<template>
  <div class="popup_wrap" style="width:1500px; height: 700px">
    <div class="popup_dim" id="sch_popup">
      <component
        v-if="customComponentPop"
        :is="customComponentPop"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </div>
    <button class="layer_close" @click.prevent="goBack">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020G070.162') }}</h1>
      <div class="content_box mt10">
        <div class="flex_box mb15 cp-parentnode">
          <h2 class="content_title">{{ $t('cp.CTRL020G070.155') }}</h2>
          <span class="ml_auto cal_btn">
            <a @click.prevent="openPopup('TargetFactorPop',0)" class="button lg blue mr5">{{ $t('cp.CTRL020G070.156') }}</a>
          </span>
        </div>
        <div class="mt10" v-for="(cond, condIdx) in factorConditionList" :key="condIdx">
          <div v-if="cond.factor === 'D'" class="mt20">
            <table class="tbl_col">
              <colgroup>
                <col width="25%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="5%">
              </colgroup>
              <thead>
                <tr>
                  <th scope="row" rowspan="2">{{ $t('cp.CTRL020G070.021') }}</th>
                  <th scope="col" rowspan="2">{{ $t('cp.CTRL020G070.022') }}</th>
                  <th scope="col" colspan="2">{{ $t('cp.CTRL020G070.163') }} 1</th>
                  <th scope="col" colspan="2">{{ $t('cp.CTRL020G070.163') }} 2</th>
                  <th scope="col" colspan="2">{{ $t('cp.CTRL020G070.163') }} 3</th>
                  <th scope="col" rowspan="2"></th>
                </tr>
                <tr>
                  <th class="border_left">{{ $t('cp.CTRL020G070.025') }}</th>
                  <th>{{ $t('cp.CTRL020G070.026') }}</th>
                  <th>{{ $t('cp.CTRL020G070.025') }}</th>
                  <th>{{ $t('cp.CTRL020G070.026') }}</th>
                  <th>{{ $t('cp.CTRL020G070.025') }}</th>
                  <th>{{ $t('cp.CTRL020G070.026') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="tbl_form">
                      <span class="dv cp-parentnode">
                        <span class="cp-flex">
                          <input type="text" v-model="cond.factorNm">
                        </span>
                      </span>
                      <span class="dv wid20">
                        <button class="tbl_icon search" @click.prevent="openPopup('TargetFactorListPop', condIdx )">Search</button>
                      </span>
                      <span class="dv wid25" v-show="cond.inputUseYn === 'Y'"><input type="text" v-model="cond.factorOpt1" style="padding:0 2px;" :disabled="isAllShipper()"></span>
                      <span class="dv wid25" v-show="cond.inputUseYn === 'Y'"><input type="text" v-model="cond.factorOpt2" style="padding:0 2px;" :disabled="isAllShipper()"></span>
                    </div>
                  </td>
                  <td>
                    <select v-model="cond.periodDiv" :disabled="isAllShipper() || cond.periodUseYn === '' || cond.periodUseYn === 'N'">
                      <option value="">{{ $t('cp.COMMON.002') }}</option>
                      <option v-for="(item, idx) in eachPeriodGbnList[cond.factorCd]" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                    </select>
                  </td>
                  <td>
                    <!--                    <e-date-range-picker-idx-->
                    <!--                      :sdate="cond.periodFr"-->
                    <!--                      :edate="cond.periodTo"-->
                    <!--                      :line="`${condIdx}1`"-->
                    <!--                      @change="changeDateRange"-->
                    <!--                    />-->
                    <div class="input_calendar short">
                      <e-date-picker v-model="cond.periodFr" style="width:100px" />
                    </div>
                  </td>
                  <td>
                    <div class="input_calendar short">
                      <e-date-picker v-model="cond.periodTo" style="width:100px" />
                    </div>
                  </td>
                  <td>
                    <div v-if="cond.option === '2' || cond.option === '3'" class="input_calendar short">
                      <e-date-picker v-model="cond.periodFr1" style="width:100px" />
                    </div>
                    <button v-if="cond.option === '1'" class="tbl_icon plus" @click.prevent="addCondDate(condIdx,'2')">plus</button>
                  </td>
                  <td>
                    <div v-if="cond.option === '2' || cond.option === '3'" class="input_calendar short">
                      <e-date-picker v-model="cond.periodTo1" style="width:100px" />
                    </div>
                  </td>
                  <td>
                    <div v-if="cond.option === '3'" class="input_calendar short">
                      <e-date-picker v-model="cond.periodFr2" style="width:100px" />
                    </div>
                    <button v-if="cond.option === '2'" class="tbl_icon plus" @click.prevent="addCondDate(condIdx,'3')">plus</button>
                  </td>
                  <td>
                    <div v-if="cond.option === '3'" class="input_calendar short">
                      <e-date-picker v-model="cond.periodTo2" style="width:100px" />
                    </div>
                  </td>
                  <td><button class="tbl_icon minus" @click.prevent="removeCondRows(condIdx)">minus</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="cond.factor === 'I'" class="mt20">
            <table class="tbl_col">
              <colgroup>
                <col width="25%">
                <col width="10%">
                <col width="15%">
                <col width="15%">
                <col width="15%">
                <col width="15%">
                <col width="5%">
              </colgroup>
              <thead>
                <tr>
                  <th scope="row" rowspan="2">{{ $t('cp.CTRL020G070.021') }}</th>
                  <th scope="col" rowspan="2">{{ $t('cp.CTRL020G070.022') }}</th>
                  <th scope="col" colspan="2">{{ $t('cp.CTRL020G070.163') }} A</th>
                  <th scope="col" colspan="2">{{ $t('cp.CTRL020G070.163') }} B</th>
                  <th scope="col" rowspan="2"></th>
                </tr>
                <tr>
                  <th class="border_left">{{ $t('cp.CTRL020G070.025') }}</th>
                  <th>{{ $t('cp.CTRL020G070.026') }}</th>
                  <th>{{ $t('cp.CTRL020G070.025') }}</th>
                  <th>{{ $t('cp.CTRL020G070.026') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="tbl_form">
                      <span class="dv cp-parentnode">
                        <span class="cp-flex">
                          <input type="text" v-model="cond.factorNm" readonly :disabled="isAllShipper()">
                        </span>
                      </span>
                      <span class="dv wid20">
                        <button class="tbl_icon search" @click.prevent="openPopup('TargetFactorListPop', condIdx )">Search</button>
                      </span>
                      <span class="dv wid25" v-show="cond.inputUseYn === 'Y'"><input type="text" v-model="cond.factorOpt1" style="padding:0 2px;" :disabled="isAllShipper()"></span>
                      <span class="dv wid25" v-show="cond.inputUseYn === 'Y'"><input type="text" v-model="cond.factorOpt2" style="padding:0 2px;" :disabled="isAllShipper()"></span>
                    </div>
                  </td>
                  <td>
                    <select v-model="cond.periodDiv" :disabled="isAllShipper() || cond.periodUseYn === '' || cond.periodUseYn === 'N'">
                      <option value="">{{ $t('cp.COMMON.002') }}</option>
                      <option v-for="(item, idx) in eachPeriodGbnList[cond.factorCd]" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                    </select>
                  </td>
                  <td>
                    <div class="input_calendar short">
                      <e-date-picker v-model="cond.periodFr" style="width:120px" />
                    </div>
                  </td>
                  <td>
                    <div class="input_calendar short">
                      <e-date-picker v-model="cond.periodTo" style="width:120px" />
                    </div>
                  </td>
                  <td>
                    <div class="input_calendar short">
                      <e-date-picker v-model="cond.periodFr1" style="width:120px" />
                    </div>
                  </td>
                  <td>
                    <div class="input_calendar short">
                      <e-date-picker v-model="cond.periodTo1" style="width:120px" />
                    </div>
                  </td>
                  <td><button class="tbl_icon minus" @click.prevent="removeCondRows(condIdx)">minus</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex_box mt8">
          <a class="button lg blue ml_auto" @click.prevent="entryValid">ENTRY</a>
        </div>
      </div>
      <div class="content_box">
        <h2 class="content_title">{{ $t('cp.CTRL020G070.161') }}</h2>
        <div id="realgrid" style="width: 100%; height: 500px" />
        <div class="flex_box mt8">
          <a class="button lg blue ml_auto" @click.prevent="exportExcelValidChk">{{ $t('cp.CTRL020G070.105') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { GridView, LocalDataProvider } from 'realgrid'
  import scenarioTargetSet from '@/api/rest/cp/scenarioTargetSet'
  import FactorList from '@/api/rest/cp/factorList'
  import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
  import JSZip from 'jszip'

  window.JSZip = window.JSZip || JSZip

  let gridView = GridView
  let provider = LocalDataProvider

  export const fields = [
    { fieldName: 'scenarioCd', dataType: 'text' },
    { fieldName: 'andor', dataType: 'text' },
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' },
    { fieldName: 'route', dataType: 'text' },
    //{ fieldName: 'route1', dataType: 'text' },
    //{ fieldName: 'route2', dataType: 'text' },
    //{ fieldName: 'first', dataType: 'text' },
    //{ fieldName: 'last', dataType: 'text' },
    { fieldName: 'itemGrp', dataType: 'text' },
    { fieldName: 'itemGrpNm', dataType: 'text' },
    { fieldName: 'item', dataType: 'text' },
    { fieldName: 'itemNm', dataType: 'text' },
    { fieldName: 'cntrSzTyp', dataType: 'text' },
    { fieldName: 'cntrSz', dataType: 'text' },
    { fieldName: 'cntrTyp', dataType: 'text' },
    { fieldName: 'frtPnc', dataType: 'text' },
    { fieldName: 'bidgCst', dataType: 'text' },
    { fieldName: 'bkgShipper', dataType: 'text' },
    { fieldName: 'bkgShipperNm', dataType: 'text' },
    { fieldName: 'salUnoBs', dataType: 'text' },
    { fieldName: 'actShipper', dataType: 'text' },
    { fieldName: 'actShipperNm', dataType: 'text' },
    { fieldName: 'hcneShipper', dataType: 'text' },
    { fieldName: 'hcneShipperNm', dataType: 'text' },
    { fieldName: 'salUnoHcs', dataType: 'text' },
    { fieldName: 'profile', dataType: 'text' },
    { fieldName: 'profileNm', dataType: 'text' },
    { fieldName: 'profileCatNm', dataType: 'text' },
    { fieldName: 'profileTel', dataType: 'text' },
    { fieldName: 'profileEml', dataType: 'text' },
    { fieldName: 'chk', dataType: 'text' }
  ]

  export const columns = [
    { name: 'chk', fieldName: 'chk', type: 'text', header: { text: 'Check' }, visible: false },
    // { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { template: `<span class='porCtr'>${app.$t('cp.CTRL020G070.040')}</span>`, text: app.$t('cp.CTRL020G070.040') }, width: 80, editable: false },
    // { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { template: `<span class="porPlc"'>${app.$t('cp.CTRL020G070.041')}</span>`, text: app.$t('cp.CTRL020G070.041') }, width: 80, editable: false },
    // { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { template: `<span class="dlyCtr"'>${app.$t('cp.CTRL020G070.042')}</span>`, text: app.$t('cp.CTRL020G070.042') }, width: 80, editable: false },
    // { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { template: `<span class="dlyPlc"'>${app.$t('cp.CTRL020G070.043')}</span>`, text: app.$t('cp.CTRL020G070.043') }, width: 80, editable: false },
    { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { template: '<span class="porCtr">POR(CN)</span>', text: 'POR(CN)' }, width: 80, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { template: '<span class="porPlc">POR(Port)</span>', text: 'POR(Port)' }, width: 80, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { template: '<span class="dlyCtr">DLY(CN)</span>', text: 'DLY(CN)' }, width: 80, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { template: '<span class="dlyPlc">DLY(Port)</span>', text: 'DLY(Port)' }, width: 80, editable: false },
    { name: 'route', fieldName: 'route', type: 'text', header: { template: `<span class="route"'>${app.$t('cp.CTRL020G070.134')}</span>` }, width: 200, editable: false, visible: false },
    //{ name: 'route1', fieldName: 'route1', type: 'text', header: { template: `<span class="route1"'>${app.$t('cp.CTRL020G070.130')}1</span>`, text: app.$t('cp.CTRL020G070.130') }, width: 70, editable: false },
    //{ name: 'route2', fieldName: 'route2', type: 'text', header: { template: `<span class="route2"'>${app.$t('cp.CTRL020G070.130')}2</span>`, text: app.$t('cp.CTRL020G070.130') }, width: 70, editable: false },
    //{ name: 'first', fieldName: 'first', type: 'text', header: { template: `<span class="first"'>${app.$t('cp.CTRL020G070.140')}</span>`, text: app.$t('cp.CTRL020G070.140') }, width: 70, editable: false },
    //{ name: 'last', fieldName: 'last', type: 'text', header: { template: `<span class="last"'>${app.$t('cp.CTRL020G070.141')}</span>`, text: app.$t('cp.CTRL020G070.141') }, width: 70, editable: false },
    { name: 'itemGrp', fieldName: 'itemGrp', type: 'text', visible: false },
    { name: 'itemGrpNm', fieldName: 'itemGrpNm', type: 'text', header: { template: `<span class="itemGrpNm"'>${app.$t('cp.CTRL020G070.103')}</span>`, text: app.$t('cp.CTRL020G070.103') }, width: 110, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'item', fieldName: 'item', visible: false },
    { name: 'itemNm', fieldName: 'itemNm', type: 'text', header: { template: `<span class="itemNm"'>${app.$t('cp.CTRL020G070.044')}</span>`, text: app.$t('cp.CTRL020G070.044') }, width: 110, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'cntrSzTyp', fieldName: 'cntrSzTyp', type: 'text', visible: false },
    { name: 'cntrSz', fieldName: 'cntrSz', type: 'text', header: { template: `<span class="cntrSz"'>${app.$t('cp.CTRL020G070.136')}</span>`, text: app.$t('cp.CTRL020G070.136') }, width: 70, editable: false },
    { name: 'cntrTyp', fieldName: 'cntrTyp', type: 'text', header: { template: `<span class="cntrTyp"'>${app.$t('cp.CTRL020G070.137')}</span>`, text: app.$t('cp.CTRL020G070.137') }, width: 70, editable: false },
    { name: 'frtPnc', fieldName: 'frtPnc', type: 'text', header: { template: `<span class="frtPnc"'>${app.$t('cp.CTRL020G070.138')}</span>`, text: app.$t('cp.CTRL020G070.138') }, width: 70, editable: false },
    { name: 'bidgCst', fieldName: 'bidgCst', type: 'text', header: { template: `<span class="bidgCst"'>${app.$t('cp.CTRL020G070.139')}</span>`, text: app.$t('cp.CTRL020G070.139') }, width: 100, editable: false },
    { name: 'bkgShipper', fieldName: 'bkgShipper', type: 'text', header: { template: `<span class="bkgShipper"'>${app.$t('cp.CTRL020G070.045')}</span>`, text: app.$t('cp.CTRL020G070.045') }, width: 110, editable: false, renderer: { showTooltip: true } },
    { name: 'bkgShipperNm', fieldName: 'bkgShipperNm', type: 'text', header: { template: `<span class="bkgShipperNm"'>${app.$t('cp.CTRL020G070.046')}</span>`, text: app.$t('cp.CTRL020G070.046') }, width: 140, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'salUnoBs', fieldName: 'salUnoBs', type: 'text', header: { template: `<span class="salUnoBs"'>${app.$t('cp.CTRL020G070.047')}</span>`, text: app.$t('cp.CTRL020G070.047') }, visible: false, width: 110, editable: false, renderer: { showTooltip: true } },
    { name: 'actShipper', fieldName: 'actShipper', visible: false },
    { name: 'actShipperNm', fieldName: 'actShipperNm', type: 'text', header: { template: `<span class="actShipperNm"'>${app.$t('cp.CTRL020G070.048')}</span>`, text: app.$t('cp.CTRL020G070.048') }, width: 140, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'hcneShipper', fieldName: 'hcneShipper', type: 'text', header: { template: `<span class="hcneShipper"'>${app.$t('cp.CTRL020G070.049')}</span>`, text: app.$t('cp.CTRL020G070.049') }, width: 110, editable: false, renderer: { showTooltip: true } },
    { name: 'hcneShipperNm', fieldName: 'hcneShipperNm', type: 'text', header: { template: `<span class="hcneShipperNm"'>${app.$t('cp.CTRL020G070.050')}</span>`, text: app.$t('cp.CTRL020G070.050') }, width: 140, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'salUnoHcs', fieldName: 'salUnoHcs', type: 'text', header: { template: `<span class="salUnoHcs"'>${app.$t('cp.CTRL020G070.051')}</span>`, text: app.$t('cp.CTRL020G070.051') }, visible: false, width: 70, editable: false, renderer: { showTooltip: true } },
    { name: 'profile', fieldName: 'profile', type: 'text', visible: false },
    { name: 'profileNm', fieldName: 'profileNm', type: 'text', header: { template: `<span class="profileNm"'>${app.$t('cp.CTRL020G070.053')}</span>`, text: app.$t('cp.CTRL020G070.053') }, width: 110, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'profileCatNm', fieldName: 'profileCatNm', type: 'text', header: { template: `<span class="profileCatNm"'>${app.$t('cp.CTRL020G070.104')}</span>`, text: app.$t('cp.CTRL020G070.104') }, width: 110, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'profileTel', fieldName: 'profileTel', type: 'text', header: { template: `<span class="profileTel"'>${app.$t('cp.CTRL020G070.054')}</span>`, text: app.$t('cp.CTRL020G070.054') }, visible: false, width: 130, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'profileEml', fieldName: 'profileEml', type: 'text', header: { template: `<span class="profileEml"'>${app.$t('cp.CTRL020G070.055')}</span>`, text: app.$t('cp.CTRL020G070.055') }, visible: false, width: 150, editable: false, styleName: 'text_left', renderer: { showTooltip: true } }
  ]

  export const layout = [
    'porCtr',
    'porPlc',
    'dlyCtr',
    'dlyPlc',
    'route',
    /*{
      name: 'routeGrp',
      direction: 'horizontal',
      items: [
        'route1',
        'route2',
        'first',
        'last'
      ],
      header: {
        text: app.$t('cp.CTRL020G070.134')
      }
    },
    */
    'itemGrp',
    'itemGrpNm',
    'item',
    'itemNm',
    'cntrSzTyp',
    {
      name: 'cntrSzTypGrp',
      direction: 'horizontal',
      items: [
        'cntrSz',
        'cntrTyp'
      ],
      header: {
        text: app.$t('cp.CTRL020G070.131')
      }
    },
    'frtPnc',
    'bidgCst',
    'bkgShipper',
    'bkgShipperNm',
    'salUnoBs',
    'actShipper',
    'actShipperNm',
    'hcneShipper',
    'hcneShipperNm',
    'salUnoHcs',
    'profile',
    'profileNm',
    'profileCatNm',
    'profileTel',
    'profileEml'
  ]

  export default {
    name: 'TargetFactorCalcPop',
    components: {
      EDatePicker: () => import('@/components/common/EDatePicker'),
      EDateRangePickerIdx: () => import('@/components/common/EDateRangePickerIdx'),
      TargetFactorListPop: () => import('@/pages/cp/popup/TargetFactorListPop'),
      TargetFactorPop: () => import('@/pages/cp/popup/TargetFactorPop')
    },
    props: {
      parentInfo: {
        type: Object,
        default: function () {
          return {
            position: ''
          }
        }
      }
    },
    data: function () {
      return {
        customComponentPop: null,
        selectFunc: null,
        confirmYn: '',
        popIdx: null,
        factorConditionList: [],
        eachPeriodGbnList: [],
        gridList: [],
        tempList: [],
        condNoList: [],
        send: null,
        scenarioCd: '',
        today: '',
        groupKeyCheck: {
          scenarioCd: 'Y',
          porCtrYn: 'Y',
          porPlcYn: 'Y',
          dlyCtrYn: 'Y',
          dlyPlcYn: 'Y',
          routeYn: 'Y',
          itemYn: 'Y',
          bkgShipperYn: 'Y',
          cntrSzTypYn: 'Y',
          frtPncYn: 'Y',
          bidgCstYn: 'Y',
          actShipperYn: 'Y',
          hcneShipperYn: 'Y',
          profileYn: 'Y'
        }
      }
    },
    computed: {
    },
    watch: {
    },
    created () {
      this.today = this.$ekmtcCommon.getToday().replaceAll('-', '')
    },
    mounted: function () {
      const $vm = this
      provider = new LocalDataProvider(true)
      gridView = new GridView('realgrid')
      // 그리드 필드 생성
      provider.setFields(fields)
      gridView.setColumns(columns)
      gridView.setColumnLayout(layout)
      gridView.setDataSource(provider)

      gridView.header.heights = [22, 23]
      gridView.header.showTooltip = undefined
      gridView.footers.visible = false
      gridView.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView.setStateBar({
        visible: false
      })
      gridView.setRowIndicator({
        visible: false
      })
      gridView.setCheckBar({
        visible: true,
        syncHeadCheck: true
      })
      gridView.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: false,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView.setFilteringOptions({
        selector: {
          style: 'cp-oldfiltering'
        }
      })

      $vm.getEachPeriodGbnList()
      $vm.setInfo()
      this.groupKeyCheck = this.parentInfo.groupKeyCheck
      this.tempList = this.parentInfo.gridList
      this.scenarioCd = this.parentInfo.scenarioCd
      $vm.updateGridVisibleOpt('')
      provider.setRows(this.gridList)
    },
    methods: {
      isAllShipper () {
        //return this.targetInfo.allShipperYn === 'Y'
        return false
      },
      entryValid () {
        for (let i = 0; i < this.factorConditionList.length; i++) {
          if (this.factorConditionList[i].factorCd === '' ||
              this.factorConditionList[i].periodDiv === '' ||
              this.factorConditionList[i].periodFr === '' ||
              this.factorConditionList[i].periodTo === '') {
            const idx = i + 1
            this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.166'), [idx]))
            //this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.086'), [cond.andor.replace('OR-AND-', ''), cond.factorNo]))

            //this.openAlert((i + 1) + '번째 조건의 팩터를 확인 하세요.')
            return
          }

          if (this.factorConditionList[i].option === '1') {
            if (this.factorConditionList[i].periodFr === '' || this.factorConditionList[i].periodTo === '') {
              const idx = i + 1
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.166'), [idx]))
              //this.openAlert((i + 1) + '번째 조건의 기간을 확인 하세요.')
              return
            }

            if (this.factorConditionList[i].periodFr > this.factorConditionList[i].periodTo) {
              const idx = i + 1
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.167'), [idx]))
              return
            }
          }

          if (this.factorConditionList[i].option === '0' || this.factorConditionList[i].option === '2') {
            if (this.factorConditionList[i].periodFr === '' || this.factorConditionList[i].periodTo === '' ||
                this.factorConditionList[i].periodFr1 === '' || this.factorConditionList[i].periodTo1 === '') {
              const idx = i + 1
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.166'), [idx]))
              //this.openAlert((i + 1) + '번째 조건의 기간을 확인 하세요.')
              return
            }

            if (this.factorConditionList[i].periodFr > this.factorConditionList[i].periodTo ||
              this.factorConditionList[i].periodFr1 > this.factorConditionList[i].periodTo1) {
              const idx = i + 1
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.167'), [idx]))
              return
            }
          }

          if (this.factorConditionList[i].option === '3') {
            if (this.factorConditionList[i].periodFr === '' || this.factorConditionList[i].periodTo === '' ||
                this.factorConditionList[i].periodFr1 === '' || this.factorConditionList[i].periodTo1 === '' ||
                this.factorConditionList[i].periodFr2 === '' || this.factorConditionList[i].periodTo2 === '') {
              const idx = i + 1
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.166'), [idx]))
              //this.openAlert((i + 1) + '번째 조건의 기간을 확인 하세요.')
              return
            }

            if (this.factorConditionList[i].periodFr > this.factorConditionList[i].periodTo ||
              this.factorConditionList[i].periodFr1 > this.factorConditionList[i].periodTo1 ||
              this.factorConditionList[i].periodFr2 > this.factorConditionList[i].periodTo2) {
              const idx = i + 1
              this.openAlert(this.convertCPLocale(this.$t('cp.CTRL020G070.167'), [idx]))
              return
            }
          }
        }
        this.entryAction()
      },
      removeCondRows (idx) {
        this.factorConditionList.splice(idx, 1)
      },
      addCondDate (idx, val) {
        this.factorConditionList[idx].option = val
      },
      async openPopup (compNm, idx) {
        if (compNm === 'TargetFactorPop') {
          if (this.factorConditionList.length > 8) {
            this.openAlert(this.$t('cp.CTRL020G070.165'))
            return
          }
        }
        this.customComponentPop = compNm
        this.selectFunc = this.callbackTarget
        this.popIdx = idx
        this.$ekmtcCommon.layerOpen('#sch_popup')
      },
      async getEachPeriodGbnList () {
        const periodGbnListRes = await cpCommonInfo.cpCommonCodeList('CP01').catch(err => { console.log(err) })
        const periodGbnList = periodGbnListRes.data
        FactorList.factorList().then(response => {
          this.eachPeriodGbnList = Object.fromEntries(response.data.map((row) => [row.factorCd, row.refTable1.split('.')[1]]))
          const periodMapper = {
            WB300S: [{ detailCd: 'P01', detailNm: this.$t('cp.CTRL020G070.058') }, { detailCd: 'P02', detailNm: this.$t('cp.CTRL020G070.059') }],
            WB302S: [{ detailCd: 'P01', detailNm: this.$t('cp.CTRL020G070.058') }, { detailCd: 'P02', detailNm: this.$t('cp.CTRL020G070.059') }, { detailCd: 'P06', detailNm: this.$t('cp.CTRL020G070.060') }],
            WB303S: [{ detailCd: 'P03', detailNm: this.$t('cp.CTRL020G070.061') }],
            WB304I: [{ detailCd: 'P03', detailNm: this.$t('cp.CTRL020G070.061') }],
            SA271H_TEMP: [{ detailCd: 'P03', detailNm: this.$t('cp.CTRL020G070.061') }],
            SA271H: [{ detailCd: 'P03', detailNm: this.$t('cp.CTRL020G070.061') }]
          }

          Object.entries(this.eachPeriodGbnList).forEach((elem, idx) => {
            const [factorCd, refTable] = elem
            if (Object.keys(periodMapper).includes(refTable)) {
              this.eachPeriodGbnList[factorCd] = periodMapper[refTable]
            } else if (factorCd === 'FC000') {
              this.eachPeriodGbnList[factorCd] = ['']
            } else {
              this.eachPeriodGbnList[factorCd] = periodGbnList
            }
          })
        }).catch(err => {
          console.log(err)
        })
      },
      closePopup (obj) {
        if (obj === undefined || obj === '' || obj === null) {
          this.customComponentPop = null
          this.$ekmtcCommon.layerClose('#sch_popup')
          return
        }
        this.customComponentPop = null
        this.$ekmtcCommon.layerClose('#sch_popup')
      },
      callbackTarget (obj) {
        if (obj !== undefined) {
          if (obj.target === 'TargetFactorPop') {
            if (obj.val === 'D') {
              // 기간별 비교
              this.factorConditionList.push(
                {
                  factor: 'D',
                  option: '1',
                  andor: `OR-AND-${this.factorConditionList.length + 1}`,
                  compUseYn: 'Y',
                  factorNm: '',
                  factorCd: '',
                  compareOpt1: '',
                  compareOpt2: '',
                  compareOpt3: '',
                  equlSign: 'S04',
                  factorCdC: '0',
                  factorNmC: '0',
                  factorNo: '1',
                  factorOpt1: '',
                  factorOpt1C: '',
                  factorOpt2: '',
                  factorOpt2C: '',
                  periodDiv: '',
                  periodFr: '',
                  periodTo: '',
                  periodFr1: '',
                  periodTo1: '',
                  periodFr2: '',
                  periodTo2: '',
                  scenarioCd: this.scenarioCd
                }
              )
              //`OR-AND-${this.send.condList.length + 1}`
            } else if (obj.val === 'I') {
              // 증감 비교
              this.factorConditionList.push(
                {
                  factor: 'I',
                  option: '0',
                  andor: `OR-AND-${this.factorConditionList.length + 1}`,
                  compUseYn: 'Y',
                  factorNm: '',
                  factorCd: '',
                  compareOpt1: '',
                  compareOpt2: '',
                  compareOpt3: '',
                  equlSign: 'S04',
                  factorCdC: '0',
                  factorNmC: '0',
                  factorNo: '1',
                  factorOpt1: '',
                  factorOpt1C: '',
                  factorOpt2: '',
                  factorOpt2C: '',
                  periodDiv: '',
                  periodFr: '',
                  periodTo: '',
                  periodFr1: '',
                  periodTo1: '',
                  periodFr2: '',
                  periodTo2: '',
                  scenarioCd: this.scenarioCd
                }
              )
            }
          } else if (obj.target === 'TargetFactorListPop') {
            this.factorConditionList[this.popIdx].factorNm = obj.val.factorNm
            this.factorConditionList[this.popIdx].factorCd = obj.val.factorCd
            this.factorConditionList[this.popIdx].refTable1 = obj.val.refTable1
            this.factorConditionList[this.popIdx].factorType = obj.val.factorType
            this.factorConditionList[this.popIdx].tbIdYn = obj.val.tbIdYn
            this.factorConditionList[this.popIdx].periodUseYn = obj.val.periodUseYn
            this.factorConditionList[this.popIdx].inputUseYn = obj.val.inputUseYn
          }
        }
      },
      entryAction () {
        this.send = this.parentInfo
        //this.sendData.condList = this.factorConditionList
        this.send.keyCheck.allShipperYn = 'N'
        scenarioTargetSet.saveScenarioTarget(this.postProcSend(this.send)).then(response => {
          if (response.data === 'OK') {
            /*
            this.groupKeyCheck.porCtrYn = this.sendData.keyCheck.porCtrYn
            this.groupKeyCheck.porPlcYn = this.sendData.keyCheck.porPlcYn
            this.groupKeyCheck.dlyCtrYn = this.sendData.keyCheck.dlyCtrYn
            this.groupKeyCheck.dlyPlcYn = this.sendData.keyCheck.dlyPlcYn
            this.groupKeyCheck.routeYn = this.sendData.keyCheck.routeYn
            this.groupKeyCheck.itemYn = this.sendData.keyCheck.itemYn
            this.groupKeyCheck.cntrSzTypYn = this.sendData.keyCheck.cntrSzTypYn
            this.groupKeyCheck.frtPncYn = this.sendData.keyCheck.frtPncYn
            this.groupKeyCheck.bidgCstYn = this.sendData.keyCheck.bidgCstYn
            this.groupKeyCheck.bkgShipperYn = this.sendData.keyCheck.bkgShipperYn
            this.groupKeyCheck.actShipperYn = this.sendData.keyCheck.actShipperYn
            this.groupKeyCheck.hcneShipperYn = this.sendData.keyCheck.hcneShipperYn
            this.groupKeyCheck.profileYn = this.sendData.keyCheck.profileYn
            this.groupKeyCheck.targetConfirmed = false
            this.groupKeyCheck.isEntry = 'Y'
            this.groupKeyCheck.afterEntry = 'Y'
            this.groupKeyCheck.scenarioCd = this.scenarioCd
            */
            this.groupKeyCheck.isEntry = 'N'
            this.groupKeyCheck.afterEntry = 'Y'
            this.targetConfirmed = false

            this.groupBy('')
          } else {
            scenarioTargetSet.deleteTempFactorOption(this.groupKeyCheck).catch(err => { console.log(err) })
            this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL020G070.089'), () => {})
          }
        }).catch(err => {
          scenarioTargetSet.deleteTempFactorOption(this.groupKeyCheck).catch(err => { console.log(err) })
          console.log(err)
        })
      },
      groupBy (typ) {
        if (typ === '') {
          //this.groupKeyCheck.scenarioCd = this.scenarioCd
          scenarioTargetSet.targetGroupby(this.groupKeyCheck).then(response => {
            scenarioTargetSet.deleteTempFactorOption(this.groupKeyCheck).catch(err => { console.log(err) })
            //this.setGridData(response.data)
            this.setGridList(this.setGridData(response.data))
            this.updateGridVisibleOpt('')
            //this.setFilter()
            //this.updateSorting()
            //this.updateGridVisibleOpt(typ)
            //this.updateCheckState()
            //this.groupKeyCheck.isEntry = 'N'
          }).catch(err => {
            console.error(err)
          })
        }
      },
      postProcSend (send) {
        const sendCopy = JSON.parse(JSON.stringify(send))
        const postProcCondRow = (condRow) => {
          return condRow
        }
        const sendCond = ([].concat(...sendCopy.condList).map(condRow => condRow))
        let idx = 0
        this.condNoList = []
        //sendCond = [...send.condList]
        //sendCond = send.condList
        for (const cond of this.factorConditionList) {
          const condData = {
            compUseYn: 'Y',
            compareOpt1: '',
            compareOpt2: '',
            compareOpt3: '',
            equlSign: 'S01',
            factorCdC: '0',
            factorNmC: '0',
            factorNo: '1',
            factorOpt1: '',
            factorOpt1C: '',
            factorOpt2: '',
            factorOpt2C: '',
            periodDiv: cond.periodDiv,
            scenarioCd: cond.scenarioCd,
            factorNm: cond.factorNm,
            factorCd: cond.factorCd,
            refTable1: cond.refTable1,
            factorType: cond.factorType,
            tbIdYn: cond.tbIdYn,
            periodUseYn: cond.periodUseYn,
            inputUseYn: cond.inputUseYn
          }

          if (cond.factor === 'D') {
            idx++
            if (cond.option === '1') {
              this.condNoList.push({ condNo: `${idx}11`, periodFr: cond.periodFr, periodTo: cond.periodTo })
              sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}11`, periodFr: cond.periodFr, periodTo: cond.periodTo } })
            } else if (cond.option === '2') {
              this.condNoList.push({ condNo: `${idx}21`, periodFr: cond.periodFr, periodTo: cond.periodTo })
              this.condNoList.push({ condNo: `${idx}22`, periodFr: cond.periodFr1, periodTo: cond.periodTo1 })
              sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}21`, periodFr: cond.periodFr, periodTo: cond.periodTo } })
              sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}22`, periodFr: cond.periodFr1, periodTo: cond.periodTo1 } })
            } else if (cond.option === '3') {
              this.condNoList.push({ condNo: `${idx}31`, periodFr: cond.periodFr, periodTo: cond.periodTo })
              this.condNoList.push({ condNo: `${idx}32`, periodFr: cond.periodFr1, periodTo: cond.periodTo1 })
              this.condNoList.push({ condNo: `${idx}33`, periodFr: cond.periodFr2, periodTo: cond.periodTo2 })
              sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}31`, periodFr: cond.periodFr, periodTo: cond.periodTo } })
              sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}32`, periodFr: cond.periodFr1, periodTo: cond.periodTo1 } })
              sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}33`, periodFr: cond.periodFr2, periodTo: cond.periodTo2 } })
            }
          } else if (cond.factor === 'I') {
            idx++
            this.condNoList.push({ condNo: `${idx}41`, periodFr: cond.periodFr, periodTo: cond.periodTo })
            this.condNoList.push({ condNo: `${idx}42`, periodFr: cond.periodFr1, periodTo: cond.periodTo1 })
            sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}41`, periodFr: cond.periodFr, periodTo: cond.periodTo } })
            sendCond.push({ ...condData, ...{ andor: `OR-AND-${idx}42`, periodFr: cond.periodFr1, periodTo: cond.periodTo1 } })
          }
        }
        //this.send.condList = sendCond

        const joinTargetList = ['porPlc', 'dlyPlc', 'route', 'itemGrp', 'item', 'cntrSzTyp', 'bkgShipper', 'actShipper', 'hcneShipper', 'profile']

        const sendPostProc = {
          keyCheck: sendCopy.keyCheck,
          groupKeyCheck: sendCopy.groupKeyCheck,
          keyInput: [...sendCopy.keyInput].map((row) => {
            for (const key of joinTargetList) {
              row[key] = Array.isArray(row[key]) && row[key].length !== 0 ? row[key].join(',') : ''
            }
            return row
          }),
          condList: sendCond
        }

        return sendPostProc
      },
      setGridData (tpData) {
        const filterData = tpData.filter(temp => temp.andor.length > 9)
        let checkList = []
        for (const data of filterData) {
          for (const temp of this.tempList) {
            let chkFlag = true
            if (chkFlag === true && this.groupKeyCheck.porCtrYn === 'Y') {
              if (temp.porCtr !== data.porCtr) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.porPlcYn === 'Y') {
              if (temp.porPlc !== data.porPlc) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.dlyCtrYn === 'Y') {
              if (temp.dlyCtr !== data.dlyCtr) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.dlyPlcYn === 'Y') {
              if (temp.dlyPlc !== data.dlyPlc) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.itemYn === 'Y') {
              if (temp.item !== data.item) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.cntrSzTypYn === 'Y') {
              if (temp.cntrSzTyp !== data.cntrSzTyp) {
                //chkFlag = false // FC0PFM001, FC0PFM009, FC0PFM011, FC0PFM012 만 컨테이너 나옴
              }
            }
            if (chkFlag === true && this.groupKeyCheck.frtPncYn === 'Y') {
              if (temp.frtPnc !== data.frtPnc) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.bidgCstYn === 'Y') {
              if (temp.bidgCst !== data.bidgCst) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.bkgShipperYn === 'Y') {
              if (temp.bkgShipper !== data.bkgShipper) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.actShipperYn === 'Y') {
              if (temp.actShipper !== data.actShipper) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.hcneShipperYn === 'Y') {
              if (temp.hcneShipper !== data.hcneShipper) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.profileYn === 'Y') {
              //if (temp.profileNm !== data.profileNm || temp.profileCatNm !== data.profileCatNm ||
              //    temp.profileTel !== data.profileTel || temp.profileEml !== data.profileEml) {
              //  chkFlag = false
              //}
              if (temp.profile !== data.profile) {
                chkFlag = false
              }
            }
            if (chkFlag === true && this.groupKeyCheck.routeYn === 'Y') {
              if (temp.route !== data.route) {
                chkFlag = false
              }
            }

            if (chkFlag === true) {
              checkList.push(data)
              break
            }
          }
        }
        return checkList
      },
      setGridList (data) {
        if (data.length === 0) {
          this.gridList = []
          this.presGridLength = 0
          //if (this.groupKeyCheck.cntrSzTypYn === 'Y') {
            gridView.header.heights = [22, 23]
          //} else {
          //  gridView.header.heights = [45]
          //}
          gridView.columnByName('route').visible = false
          gridView.setColumnLayout(layout)
          provider.setRows([])

          return
        }

        // https://gist.github.com/mikaello/06a76bca33e5d79cdd80c162d7774e9c
        const groupByResponseData = (keys) => (array) => (
          array.reduce((acc1, cur1) => {
            keys.reduce((acc2, cur2, idx2) => {
              if (idx2 !== keys.length - 1) {
                acc2[cur1[cur2]] = acc2[cur1[cur2]] || {}
              } else {
                acc2[cur1[cur2]] = (acc2[cur1[cur2]] || []).concat(cur1)
              }
              return acc2[cur1[cur2]]
            }, acc1)
            return acc1
          }, {})
        )

        const getLeafNodes = (nodes, result = []) => {
          for (const val of Object.values(nodes)) {
            if (Array.isArray(val) && val !== undefined && val.length !== 0) {
              result.push(val)
            } else {
              result = getLeafNodes(val, result)
            }
          }
          return result
        }

        const sortbyCondFactorCd = (dynamicLayoutInfo) => {
          const order = []
          this.send.condList.map(cond => cond.map(row => {
            const andorNo = parseInt(row.andor.replace('OR-AND-', ''))
            order.push(...[`${andorNo}-${row.factorNo}-${row.factorCd}`, `${andorNo}-${row.factorNo}-${row.factorCdC}_C`])
          }))

          for (const info of Object.values(dynamicLayoutInfo)) {
            info.items.sort((a, b) => {
              if (order.indexOf(a) < order.indexOf(b)) {
                return -1
              } else if (order.indexOf(a) > order.indexOf(b)) {
                return 1
              } else {
                return 0
              }
            })
          }
        }

        const checkMapper = {
          porCtr: 'porCtrYn',
          porPlc: 'porPlcYn',
          dlyCtr: 'dlyCtrYn',
          dlyPlc: 'dlyPlcYn',
          route: 'routeYn',
          item: 'itemYn',
          cntrSzTyp: 'cntrSzTypYn',
          frtPnc: 'frtPncYn',
          bidgCst: 'bidgCstYn',
          bkgShipper: 'bkgShipperYn',
          actShipper: 'actShipperYn',
          hcneShipper: 'hcneShipperYn',
          profile: 'profileYn'
        }

        const staticColumnNm = fields.map(col => col.fieldName)
        const chkedGroupKeyYn = Object.keys(this.groupKeyCheck).filter(chk => (this.groupKeyCheck[chk] === 'Y'))
        const groupByReferKey = Object.keys(checkMapper).filter(key => (chkedGroupKeyYn.includes(checkMapper[key])))
        const routeLengthList = data.map(row => row.route?.split('-').length)
        const maxRouteCount = Math.max(...routeLengthList) > 0 ? Math.max(...routeLengthList) : 1
        const gridList = []
        const dynamicColumnInfo = {}
        const dynamicLayoutInfo = {}
        const dynamicAndorInfo = {}

        for (const pairRow of getLeafNodes(groupByResponseData(groupByReferKey)(data))) {
          const postProcRow = {}
          for (const key of staticColumnNm) {
            postProcRow[key] = pairRow[0][key]
          }

          const routeList = typeof pairRow[0].route === 'string' ? pairRow[0].route.split('-') : []
          if (routeList.length === 1) {
            postProcRow.route1 = routeList[0]
            postProcRow.first = routeList[0]
          } else if (routeList.length > 1) {
            for (let i = 0; i < maxRouteCount; i++) {
              postProcRow[`route${i + 1}`] = routeList[i]
            }
            postProcRow.first = routeList[0]
            postProcRow.last = routeList.at(-1)
          }

          const cntrSzTyp = typeof pairRow[0].cntrSzTyp === 'string' ? pairRow[0].cntrSzTyp : ''
          if (cntrSzTyp !== '') {
            const [cntrSz, cntrTyp] = cntrSzTyp.split('-')
            postProcRow.cntrSz = cntrSz
            postProcRow.cntrTyp = cntrTyp
          }

          let befMeasures = ''
          let befNumber = ''
          let befNumber1 = ''
          let befNumber2 = ''

          for (let i = 0; i < pairRow.length; i++) {
            const checkValueList = [pairRow[i].factorCd, pairRow[i].factorNm, pairRow[i].measures]

            if (checkValueList.every(value => value !== undefined)) {
              const andorNo = parseInt(pairRow[i].andor.replace('OR-AND-', ''))
              const delimiter = ' | '
              const newFactorCd = pairRow[i].factorCd.split(delimiter).map(factorCd => `${andorNo}-${factorCd}`)
              const newFactorNm = pairRow[i].factorNm.split(delimiter)
              const newMeasures = pairRow[i].measures.split(delimiter)

              for (let j = 0; j < newFactorCd.length; j++) {
                const factorCd = newFactorCd[j]
                const factorNm = newFactorNm[j]
                const measures = newMeasures[j]

                const tempAndor = factorCd.split('-')[0]
                const tempfactor = factorCd.split('-')[1] + '-' + factorCd.split('-')[2]
                const tempNo1 = tempAndor.substring(0, 1)
                const tempNo2 = tempAndor.substring(1, 2)
                const tempNo3 = tempAndor.substring(2)

                // tempNo1 + 41 번(비교대상) 내용이 없이 넘어오면 2번 채우기 전에 1번 생성한다.
                if (tempNo2 === '4' && tempNo3 === '2') {
                  if (befNumber !== tempNo1) {
                    const befFactorCd = tempNo1 + tempNo2 + '1' + '-' + tempfactor
                    const befAndorNo = tempNo1 + tempNo2 + '1'
                    if (!(befFactorCd in dynamicColumnInfo)) {
                      dynamicColumnInfo[befFactorCd] = factorNm
                    }
                    postProcRow[befFactorCd] = ''
                    if (!(befFactorCd in dynamicAndorInfo)) {
                      dynamicAndorInfo[befFactorCd] = befAndorNo
                    }
                  }
                } else if (tempNo2 === '3' && tempNo3 === '2') { // tempNo1 + 31 없이 32가 오면 31을 만들어준다.
                  if (befNumber1 !== tempNo1) {
                    const befFactorCd = tempNo1 + tempNo2 + '1' + '-' + tempfactor
                    const befAndorNo = tempNo1 + tempNo2 + '1'
                    if (!(befFactorCd in dynamicColumnInfo)) {
                      dynamicColumnInfo[befFactorCd] = factorNm
                    }
                    postProcRow[befFactorCd] = ''
                    if (!(befFactorCd in dynamicAndorInfo)) {
                      dynamicAndorInfo[befFactorCd] = befAndorNo
                    }
                  }
                } else if (tempNo2 === '3' && tempNo3 === '3') { // tempNo1 + 31,32 없이 33가 오면 31, 32 을 만들어준다.
                  if (befNumber1 !== tempNo1) {
                    const befFactorCd = tempNo1 + tempNo2 + '1' + '-' + tempfactor
                    const befAndorNo = tempNo1 + tempNo2 + '1'
                    if (!(befFactorCd in dynamicColumnInfo)) {
                      dynamicColumnInfo[befFactorCd] = factorNm
                    }
                    postProcRow[befFactorCd] = ''
                    if (!(befFactorCd in dynamicAndorInfo)) {
                      dynamicAndorInfo[befFactorCd] = befAndorNo
                    }
                  }

                  if (befNumber2 !== tempNo1) {
                    const befFactorCd = tempNo1 + tempNo2 + '2' + '-' + tempfactor
                    const befAndorNo = tempNo1 + tempNo2 + '2'
                    if (!(befFactorCd in dynamicColumnInfo)) {
                      dynamicColumnInfo[befFactorCd] = factorNm
                    }
                    postProcRow[befFactorCd] = ''
                    if (!(befFactorCd in dynamicAndorInfo)) {
                      dynamicAndorInfo[befFactorCd] = befAndorNo
                    }
                  }
                } else if (tempNo2 === '2' && tempNo3 === '2') { // tempNo1 + 21 없이 22가 오면 21을 만들어준다.
                  if (befNumber1 !== tempNo1) {
                    const befFactorCd = tempNo1 + tempNo2 + '1' + '-' + tempfactor
                    const befAndorNo = tempNo1 + tempNo2 + '1'
                    if (!(befFactorCd in dynamicColumnInfo)) {
                      dynamicColumnInfo[befFactorCd] = factorNm
                    }
                    postProcRow[befFactorCd] = ''
                    if (!(befFactorCd in dynamicAndorInfo)) {
                      dynamicAndorInfo[befFactorCd] = befAndorNo
                    }
                  }
                }

                if (!(factorCd in dynamicColumnInfo)) {
                  dynamicColumnInfo[factorCd] = factorNm
                }

                if (!(factorCd in postProcRow)) {
                  postProcRow[factorCd] = measures
                } else {
                  postProcRow[factorCd] += `, ${measures}`
                }

                if (!(factorCd in dynamicAndorInfo)) {
                  dynamicAndorInfo[factorCd] = andorNo
                }

                // [[ factor 증감 팩터 추가
                if (tempNo2 === '4' && tempNo3 === '1') {
                  befMeasures = measures
                  befNumber = tempNo1
                } else if (tempNo2 === '4' && tempNo3 === '2') {
                  const perFactorCd = tempNo1 + tempNo2 + '3' + '-' + tempfactor
                  const perAndorNo = tempNo1 + tempNo2 + '3'
                  const valA = Number(befMeasures)
                  const valB = Number(measures)
                  let perMeasures = (valB - valA) / valA * 100

                  // tempNo1 + 41 번(비교대상) 내용이 없을경우..빈값
                  if (befNumber !== tempNo1) {
                    perMeasures = ''
                  }
                  if (!Number.isFinite(perMeasures)) {
                    perMeasures = ''
                  }
                  if (!(perFactorCd in dynamicColumnInfo)) {
                    dynamicColumnInfo[perFactorCd] = factorNm
                  }
                  postProcRow[perFactorCd] = perMeasures
                  if (!(perFactorCd in dynamicAndorInfo)) {
                    dynamicAndorInfo[perFactorCd] = perAndorNo
                  }
                } else if (tempNo2 === '3' && tempNo3 === '1') {
                  befNumber1 = tempNo1
                } else if (tempNo2 === '3' && tempNo3 === '2') {
                  befNumber2 = tempNo1
                } else if (tempNo2 === '2' && tempNo3 === '1') {
                  befNumber1 = tempNo1
                }
                // ]]
              }
            }
          }
          gridList.push(postProcRow)
        }
        const validCondNoList = Array.from(new Set(Object.keys(dynamicColumnInfo).map(row => parseInt(row.split('-')[0])))).sort((a, b) => a - b)
        for (const validCondNo of validCondNoList) {
          const tempNo1 = String(validCondNo).substring(0, 1)
          const tempNo2 = String(validCondNo).substring(1, 2)
          const tempNo3 = String(validCondNo).substring(2)
          if (tempNo2 === '4' || tempNo2 === '1' || tempNo2 === '2' || tempNo2 === '3') {
            const dynamicLayoutInfoCheck = dynamicLayoutInfo[`factorGrp${tempNo1}${tempNo2}`] || ''
            if (dynamicLayoutInfoCheck === '') {
              dynamicLayoutInfo[`factorGrp${tempNo1}${tempNo2}`] = {
                name: `factorGrp${tempNo1}${tempNo2}`,
                direction: 'horizontal',
                items: [],
                header: {
                  text: ''
                }
              }
            }
          }
          // else {
          //   dynamicLayoutInfo[`factorGrp${validCondNo}`] = {
          //     name: `factorGrp${validCondNo}`,
          //     direction: 'horizontal',
          //     items: [],
          //     header: {
          //       text: ''
          //     }
          //   }
          // }
        }

        const dynamicFields = []
        const dynamicColumns = []

        for (const fieldName of Object.keys(dynamicColumnInfo)) {
          const andorNo = parseInt(fieldName.split('-')[0])
          const columnValList = gridList.map(row => row[fieldName] !== undefined ? row[fieldName] : '0')
          const dataType = columnValList.every(x => !isNaN(x)) ? 'number' : 'text'

          let subTitle = ''
          const tempNo1 = String(andorNo).substring(0, 1)
          const tempNo2 = String(andorNo).substring(1, 2)
          const tempNo3 = String(andorNo).substring(2)
          if (tempNo2 === '1' || tempNo2 === '2' || tempNo2 === '3') {
            for (const cond of this.condNoList) {
              if (cond.condNo === String(andorNo)) {
                subTitle = this.changeDateForm(cond.periodFr, '.') + '~' + this.changeDateForm(cond.periodTo, '.')
                break
              }
            }
            //subTitle = this.$t('cp.CTRL020G070.163') + tempNo3
          } else if (tempNo2 === '4') {
            if (tempNo3 === '1' || tempNo3 === '2') {
              //subTitle = this.$t('cp.CTRL020G070.163') + 'A'
              for (const cond of this.condNoList) {
                if (cond.condNo === String(andorNo)) {
                  subTitle = this.changeDateForm(cond.periodFr) + '~' + this.changeDateForm(cond.periodTo)
                  break
                }
              }
            //} else if (tempNo3 === '2') {
            //  subTitle = this.$t('cp.CTRL020G070.163') + 'B'
            } else if (tempNo3 === '3') {
              subTitle = this.$t('cp.CTRL020G070.164')
            }
          }
          dynamicFields.push(
            Object.assign({
                fieldName: fieldName, dataType: dataType
              },
              dataType === 'number' ? ({
                subType: columnValList.every(x => Number.isInteger(Number(x))) ? 'int' : ''
              }) : {})
          )

          dynamicColumns.push(
            Object.assign({
                name: fieldName,
                fieldName: fieldName,
                type: dataType,
                header: {
                  template: `<span class="${fieldName}">${subTitle}</span>`, //`<span class="${fieldName}">${dynamicColumnInfo[fieldName]}</span>`,
                  text: subTitle, //dynamicColumnInfo[fieldName],
                  showTooltip: true
                }
              },
              dataType === 'number' ? ({
                numberFormat: columnValList.every(x => Number.isInteger(Number(x))) ? '#,##0' : '#,##0.0',
                styleName: 'text_center'
              }) : {},
              {
                visible: true,
                width: 120,
                editable: false,
                renderer: { showTooltip: true }
              })
          )

          dynamicLayoutInfo[`factorGrp${tempNo1}${tempNo2}`].items.push(fieldName)
          dynamicLayoutInfo[`factorGrp${tempNo1}${tempNo2}`].header.text = dynamicColumnInfo[fieldName]
        }
        // JamSin sortbyCondFactorCd(dynamicLayoutInfo)

        gridView.setCheckBar({ syncHeadCheck: false })
        const fieldRouteIdx = fields.map(elem => elem.fieldName).indexOf('route')
        const columnRouteIdx = columns.map(elem => elem.fieldName).indexOf('route')
        const layoutRouteIdx = columnRouteIdx
        const isRouteVisible = this.groupKeyCheck.routeYn === 'Y'

        // https://forum.realgrid.com/topics/iMh4drQeXxRNwfhTM
        provider.setFields([
          ...fields.slice(0, fieldRouteIdx),
          ...Array(maxRouteCount).fill().map((_elem, idx) => ({
            fieldName: `route${idx + 1}`, type: 'text'
          })),
          { fieldName: 'first', type: 'text' },
          { fieldName: 'last', type: 'text' },
          ...fields.slice(fieldRouteIdx),
          ...dynamicFields
        ])

        gridView.setColumns([
          ...columns.slice(0, columnRouteIdx),
          ...Array(maxRouteCount).fill().map((_elem, idx) => ({
            name: `route${idx + 1}`,
            fieldName: `route${idx + 1}`,
            type: 'text',
            width: 80,
            visible: isRouteVisible,
            editable: false,
            header: {
              template: `<sapn class="route${idx + 1}">${this.$t('cp.CTRL020G070.130')}${idx + 1}</sapn>`,
              text: 'ROUTE' + (idx + 1)
            }
          })),
          {
            name: 'first',
            fieldName: 'first',
            type: 'text',
            width: 80,
            visible: isRouteVisible,
            editable: false,
            header: {
              text: this.$t('cp.CTRL020G070.140'),
              template: `<sapn class="first">${this.$t('cp.CTRL020G070.140')}</sapn>`
            }
          },
          {
            name: 'last',
            fieldName: 'last',
            type: 'text',
            width: 80,
            visible: isRouteVisible && maxRouteCount > 1,
            editable: false,
            header: {
              text: this.$t('cp.CTRL020G070.141'),
              template: `<sapn class="last">${this.$t('cp.CTRL020G070.141')}</sapn>`
            }
          },
          ...columns.slice(columnRouteIdx),
          ...dynamicColumns
        ])
        gridView.setColumnLayout([
          ...layout.slice(0, layoutRouteIdx),
          {
            name: 'route',
            direction: 'horizontal',
            items: [
              ...Array(maxRouteCount).fill().map((_elem, idx) => `route${idx + 1}`),
              'first',
              'last'
            ],
            header: {
              text: app.$t('ROUTE')
            }
          },
          ...layout.slice(layoutRouteIdx),
          ...Object.values(dynamicLayoutInfo)
        ])

        gridView.header.heights = [22, 23]
        gridView.columnByName('route').visible = false
        provider.setRows(gridList)
        gridView.setCheckBar({ syncHeadCheck: true })

        this.gridList = gridList
        this.presGridLength = gridList.length
        provider.setRows(this.gridList)
      },
      getEqualSignType (cd) {
        return this.equalSignList.filter(elem => elem.detailCd === cd)?.[0]?.type
      },
      updateGridVisibleOpt (typ) {
        if (typ === '' || typ === 'onlyChk') {
          if (this.groupKeyCheck.porCtrYn === 'Y') {
            gridView.columnByName('porCtr').visible = true
          } else {
            gridView.columnByName('porCtr').visible = false
          }
          if (this.groupKeyCheck.porPlcYn === 'Y') {
            gridView.columnByName('porPlc').visible = true
          } else {
            gridView.columnByName('porPlc').visible = false
          }
          if (this.groupKeyCheck.dlyCtrYn === 'Y') {
            gridView.columnByName('dlyCtr').visible = true
          } else {
            gridView.columnByName('dlyCtr').visible = false
          }
          if (this.groupKeyCheck.dlyPlcYn === 'Y') {
            gridView.columnByName('dlyPlc').visible = true
          } else {
            gridView.columnByName('dlyPlc').visible = false
          }
          if (this.groupKeyCheck.itemYn === 'Y') {
            gridView.columnByName('itemGrpNm').visible = true
            gridView.columnByName('itemNm').visible = true
          } else {
            gridView.columnByName('itemGrpNm').visible = false
            gridView.columnByName('itemNm').visible = false
          }
          if (this.groupKeyCheck.cntrSzTypYn === 'Y') {
            gridView.columnByName('cntrSz').visible = true
            gridView.columnByName('cntrTyp').visible = true
          } else {
            gridView.columnByName('cntrSz').visible = false
            gridView.columnByName('cntrTyp').visible = false
          }
          if (this.groupKeyCheck.frtPncYn === 'Y') {
            gridView.columnByName('frtPnc').visible = true
          } else {
            gridView.columnByName('frtPnc').visible = false
          }
          if (this.groupKeyCheck.bidgCstYn === 'Y') {
            gridView.columnByName('bidgCst').visible = true
          } else {
            gridView.columnByName('bidgCst').visible = false
          }
          if (this.groupKeyCheck.bkgShipperYn === 'Y') {
            gridView.columnByName('bkgShipper').visible = true
            gridView.columnByName('bkgShipperNm').visible = true
          } else {
            gridView.columnByName('bkgShipper').visible = false
            gridView.columnByName('bkgShipperNm').visible = false
          }
          if (this.groupKeyCheck.actShipperYn === 'Y') {
            gridView.columnByName('actShipperNm').visible = true
          } else {
            gridView.columnByName('actShipperNm').visible = false
          }
          if (this.groupKeyCheck.hcneShipperYn === 'Y') {
            gridView.columnByName('hcneShipper').visible = true
            gridView.columnByName('hcneShipperNm').visible = true
          } else {
            gridView.columnByName('hcneShipper').visible = false
            gridView.columnByName('hcneShipperNm').visible = false
          }
          if (this.groupKeyCheck.profileYn === 'Y') {
            gridView.columnByName('profileNm').visible = true
            gridView.columnByName('profileCatNm').visible = true
            gridView.columnByName('profileTel').visible = true
            gridView.columnByName('profileEml').visible = true
          } else {
            gridView.columnByName('profileNm').visible = false
            gridView.columnByName('profileCatNm').visible = false
            gridView.columnByName('profileTel').visible = false
            gridView.columnByName('profileEml').visible = false
          }
          // if (this.groupKeyCheck.routeYn === 'Y') {
          //   gridView.columnByName('route1').visible = true
          //   gridView.columnByName('route2').visible = true
          //   gridView.columnByName('first').visible = true
          //   gridView.columnByName('last').visible = true
          // } else {
          //   gridView.columnByName('route1').visible = false
          //   gridView.columnByName('route2').visible = false
          //   gridView.columnByName('first').visible = false
          //   gridView.columnByName('last').visible = false
          // }

          // 다중 조건 추가 처리
          if (this.groupKeyCheck.bkgShipperYn === 'Y' && (this.groupKeyCheck.porCtrYn === 'Y' || this.groupKeyCheck.dlyCtrYn === 'Y')) {
            gridView.columnByName('salUnoBs').visible = true
          } else {
            gridView.columnByName('salUnoBs').visible = false
          }
          if (this.groupKeyCheck.hcneShipperYn === 'Y' && (this.groupKeyCheck.porCtrYn === 'Y' || this.groupKeyCheck.dlyCtrYn === 'Y')) {
            gridView.columnByName('salUnoHcs').visible = true
          } else {
            gridView.columnByName('salUnoHcs').visible = false
          }

          //if (this.groupKeyCheck.cntrSzTypYn === 'Y' || this.groupKeyCheck.routeYn === 'Y') {
            gridView.header.heights = [25, 25]
          //} else {
          //  gridView.header.heights = [40, 23]
          //}
        }
      },
      changeDateRange (s, d, i) {
        this.factorConditionList[i].periodFr = s
        this.factorConditionList[i].periodTo = d
      },
      setInfo: function () {
        provider.setRows(this.parentInfo.confirmShipperInfo)
      },
      confirm: function () {
        this.confirmYn = 'Y'
        this.$emit('close', this.confirmYn)
        this.layerClose()
      },
      goBack: function () {
        this.confirmYn = 'N'
        this.$emit('close', this.confirmYn)
        this.layerClose()
      },
      layerClose: function () {
        $('.popup_dim').fadeOut()
        $('body,html').removeAttr('style')
        return false
      },
      exportExcelValidChk () {
        if (gridView.getCheckedRows().length === 0) {
          this.openAlert(this.$t('cp.CTRL020G070.109'))
        } else {
          this.asyncAlert(this.$t('cp.CTRL020G070.106'), 'exportExcel')
        }
      },
      async asyncAlert (msg, typ) {
        const obj = {
          alertType: 'normal',
          showXclose: true,
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$t('cp.COMMON.008'),
          customConfirmBtnText: this.$t('cp.COMMON.005'),
          customConfirmBtnClass: 'button blue lg',
          useConfirmBtn: true,
          title: this.$t('cp.CTRL020G070.001'),
          message: msg,
          type: 'info'
        }
        if (await this.$ekmtcCommon.asyncAlert(obj)) {
          switch (typ) {
            case 'exportExcel': {
              this.exportExcel()
              break
            }
            default: {
              return false
            }
          }
        }
      },
      exportExcel () {
        const dateObj = new Date()
        const date = dateObj.getFullYear() + ('0' + (dateObj.getMonth() + 1)).slice(-2) + ('0' + dateObj.getDate()).slice(-2)
        const scenarioCd = this.scenarioCd
        const scenarioNm = this.parentInfo.scenarioNm
        const owner = this.parentInfo.createUser
        const actionCd = this.parentInfo.category

        const title = scenarioNm
        const subTitle = `Scn Code: ${scenarioCd} / Owner: ${owner} / Action Code: ${actionCd}`
        const fileName = `${scenarioCd}_${owner}_factorcal_${date}.xlsx`
        const sheetName = scenarioCd

        gridView.exportGrid({
          type: 'excel',
          target: 'local',
          documentTitle: {
            message: title,
            visible: true,
            spaceTop: 0,
            spaceBottom: 0,
            height: 30,
            styleName: 'cp-excel-title'
          },
          documentSubtitle: {
            message: subTitle,
            spaceBottom: 1,
            visible: true,
            height: 20
          },
          fileName: fileName,
          showProgess: true,
          progressMessage: this.$t('cp.CTRL020G070.107'),
          indicator: 'hidden',
          applyFixed: false,
          header: 'visible',
          footer: 'hidden',
          onlyCheckedItems: true,
          compatibility: true,
          pagingAllItems: true,
          sheetAttrs: { showGridLines: '0' },
          exportTemplate: true,
          separateRows: true,
          exportGrids: [
            { grid: gridView, sheetName: sheetName }
          ]
        })

        this.openAlert(this.$t('cp.CTRL020G070.108'))
      },
      openAlert (msg) {
        let obj = {
          alertType: 'simple',
          onClose: this.closeFun,
          customCloseBtnClass: 'button blue lg simplert-test1',
          customCloseBtnText: this.$t('cp.COMMON.005'),
          useIcon: false,
          customClass: 'simple_alert',
          message: msg,
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      },
      convertCPLocale (expr, strs) {
        const regex = /\${.*?\}/g
        const patternList = expr.match(regex)
        for (let i = 0; i < patternList.length; i++) {
          if (strs[i] !== undefined) {
            expr = expr.replace(patternList[i], strs[i])
          }
        }
        return expr
      },
      changeDateForm (strDate, char) {
        let result = ''
        if (this.isEmpty(strDate)) {
          return ''
        }

        if (this.isEmpty(char)) {
          char = '.'
        }

        if (strDate !== null && strDate.length >= 8) {
          result += strDate.substring(2, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8)
        }
        return result
      },
      isEmpty (objValue) {
        if (objValue === undefined || objValue === null) {
          return true
        } else {
          return ('' + objValue).length === 0
        }
      }
    }
  }
</script>
<style>
  .desc_list ul li {
    font-size: 14px;
  }
</style>
