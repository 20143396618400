var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1500px", height: "700px" },
    },
    [
      _c(
        "div",
        { staticClass: "popup_dim", attrs: { id: "sch_popup" } },
        [
          _vm.customComponentPop
            ? _c(_vm.customComponentPop, {
                tag: "component",
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.goBack.apply(null, arguments)
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.162"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box mt10" },
          [
            _c("div", { staticClass: "flex_box mb15 cp-parentnode" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.155"))),
              ]),
              _c("span", { staticClass: "ml_auto cal_btn" }, [
                _c(
                  "a",
                  {
                    staticClass: "button lg blue mr5",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openPopup("TargetFactorPop", 0)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("cp.CTRL020G070.156")))]
                ),
              ]),
            ]),
            _vm._l(_vm.factorConditionList, function (cond, condIdx) {
              return _c("div", { key: condIdx, staticClass: "mt10" }, [
                cond.factor === "D"
                  ? _c("div", { staticClass: "mt20" }, [
                      _c("table", { staticClass: "tbl_col" }, [
                        _vm._m(0, true),
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              { attrs: { scope: "row", rowspan: "2" } },
                              [_vm._v(_vm._s(_vm.$t("cp.CTRL020G070.021")))]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", rowspan: "2" } },
                              [_vm._v(_vm._s(_vm.$t("cp.CTRL020G070.022")))]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", colspan: "2" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("cp.CTRL020G070.163")) + " 1"
                                ),
                              ]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", colspan: "2" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("cp.CTRL020G070.163")) + " 2"
                                ),
                              ]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", colspan: "2" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("cp.CTRL020G070.163")) + " 3"
                                ),
                              ]
                            ),
                            _c("th", { attrs: { scope: "col", rowspan: "2" } }),
                          ]),
                          _c("tr", [
                            _c("th", { staticClass: "border_left" }, [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.025"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.026"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.025"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.026"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.025"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.026"))),
                            ]),
                          ]),
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "tbl_form" }, [
                                _c(
                                  "span",
                                  { staticClass: "dv cp-parentnode" },
                                  [
                                    _c("span", { staticClass: "cp-flex" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: cond.factorNm,
                                            expression: "cond.factorNm",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: { value: cond.factorNm },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              cond,
                                              "factorNm",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _c("span", { staticClass: "dv wid20" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon search",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "TargetFactorListPop",
                                            condIdx
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Search")]
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: cond.inputUseYn === "Y",
                                        expression: "cond.inputUseYn === 'Y'",
                                      },
                                    ],
                                    staticClass: "dv wid25",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: cond.factorOpt1,
                                          expression: "cond.factorOpt1",
                                        },
                                      ],
                                      staticStyle: { padding: "0 2px" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isAllShipper(),
                                      },
                                      domProps: { value: cond.factorOpt1 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            cond,
                                            "factorOpt1",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: cond.inputUseYn === "Y",
                                        expression: "cond.inputUseYn === 'Y'",
                                      },
                                    ],
                                    staticClass: "dv wid25",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: cond.factorOpt2,
                                          expression: "cond.factorOpt2",
                                        },
                                      ],
                                      staticStyle: { padding: "0 2px" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isAllShipper(),
                                      },
                                      domProps: { value: cond.factorOpt2 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            cond,
                                            "factorOpt2",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: cond.periodDiv,
                                      expression: "cond.periodDiv",
                                    },
                                  ],
                                  attrs: {
                                    disabled:
                                      _vm.isAllShipper() ||
                                      cond.periodUseYn === "" ||
                                      cond.periodUseYn === "N",
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        cond,
                                        "periodDiv",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                                  ]),
                                  _vm._l(
                                    _vm.eachPeriodGbnList[cond.factorCd],
                                    function (item, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: idx,
                                          domProps: { value: item.detailCd },
                                        },
                                        [_vm._v(_vm._s(item.detailNm))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "input_calendar short" },
                                [
                                  _c("e-date-picker", {
                                    staticStyle: { width: "100px" },
                                    model: {
                                      value: cond.periodFr,
                                      callback: function ($$v) {
                                        _vm.$set(cond, "periodFr", $$v)
                                      },
                                      expression: "cond.periodFr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "input_calendar short" },
                                [
                                  _c("e-date-picker", {
                                    staticStyle: { width: "100px" },
                                    model: {
                                      value: cond.periodTo,
                                      callback: function ($$v) {
                                        _vm.$set(cond, "periodTo", $$v)
                                      },
                                      expression: "cond.periodTo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              cond.option === "2" || cond.option === "3"
                                ? _c(
                                    "div",
                                    { staticClass: "input_calendar short" },
                                    [
                                      _c("e-date-picker", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value: cond.periodFr1,
                                          callback: function ($$v) {
                                            _vm.$set(cond, "periodFr1", $$v)
                                          },
                                          expression: "cond.periodFr1",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              cond.option === "1"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon plus",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addCondDate(condIdx, "2")
                                        },
                                      },
                                    },
                                    [_vm._v("plus")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              cond.option === "2" || cond.option === "3"
                                ? _c(
                                    "div",
                                    { staticClass: "input_calendar short" },
                                    [
                                      _c("e-date-picker", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value: cond.periodTo1,
                                          callback: function ($$v) {
                                            _vm.$set(cond, "periodTo1", $$v)
                                          },
                                          expression: "cond.periodTo1",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              cond.option === "3"
                                ? _c(
                                    "div",
                                    { staticClass: "input_calendar short" },
                                    [
                                      _c("e-date-picker", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value: cond.periodFr2,
                                          callback: function ($$v) {
                                            _vm.$set(cond, "periodFr2", $$v)
                                          },
                                          expression: "cond.periodFr2",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              cond.option === "2"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon plus",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addCondDate(condIdx, "3")
                                        },
                                      },
                                    },
                                    [_vm._v("plus")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              cond.option === "3"
                                ? _c(
                                    "div",
                                    { staticClass: "input_calendar short" },
                                    [
                                      _c("e-date-picker", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value: cond.periodTo2,
                                          callback: function ($$v) {
                                            _vm.$set(cond, "periodTo2", $$v)
                                          },
                                          expression: "cond.periodTo2",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeCondRows(condIdx)
                                    },
                                  },
                                },
                                [_vm._v("minus")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                cond.factor === "I"
                  ? _c("div", { staticClass: "mt20" }, [
                      _c("table", { staticClass: "tbl_col" }, [
                        _vm._m(1, true),
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              { attrs: { scope: "row", rowspan: "2" } },
                              [_vm._v(_vm._s(_vm.$t("cp.CTRL020G070.021")))]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", rowspan: "2" } },
                              [_vm._v(_vm._s(_vm.$t("cp.CTRL020G070.022")))]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", colspan: "2" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("cp.CTRL020G070.163")) + " A"
                                ),
                              ]
                            ),
                            _c(
                              "th",
                              { attrs: { scope: "col", colspan: "2" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("cp.CTRL020G070.163")) + " B"
                                ),
                              ]
                            ),
                            _c("th", { attrs: { scope: "col", rowspan: "2" } }),
                          ]),
                          _c("tr", [
                            _c("th", { staticClass: "border_left" }, [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.025"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.026"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.025"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.026"))),
                            ]),
                          ]),
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "tbl_form" }, [
                                _c(
                                  "span",
                                  { staticClass: "dv cp-parentnode" },
                                  [
                                    _c("span", { staticClass: "cp-flex" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: cond.factorNm,
                                            expression: "cond.factorNm",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          readonly: "",
                                          disabled: _vm.isAllShipper(),
                                        },
                                        domProps: { value: cond.factorNm },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              cond,
                                              "factorNm",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _c("span", { staticClass: "dv wid20" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "tbl_icon search",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openPopup(
                                            "TargetFactorListPop",
                                            condIdx
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Search")]
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: cond.inputUseYn === "Y",
                                        expression: "cond.inputUseYn === 'Y'",
                                      },
                                    ],
                                    staticClass: "dv wid25",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: cond.factorOpt1,
                                          expression: "cond.factorOpt1",
                                        },
                                      ],
                                      staticStyle: { padding: "0 2px" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isAllShipper(),
                                      },
                                      domProps: { value: cond.factorOpt1 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            cond,
                                            "factorOpt1",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: cond.inputUseYn === "Y",
                                        expression: "cond.inputUseYn === 'Y'",
                                      },
                                    ],
                                    staticClass: "dv wid25",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: cond.factorOpt2,
                                          expression: "cond.factorOpt2",
                                        },
                                      ],
                                      staticStyle: { padding: "0 2px" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isAllShipper(),
                                      },
                                      domProps: { value: cond.factorOpt2 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            cond,
                                            "factorOpt2",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: cond.periodDiv,
                                      expression: "cond.periodDiv",
                                    },
                                  ],
                                  attrs: {
                                    disabled:
                                      _vm.isAllShipper() ||
                                      cond.periodUseYn === "" ||
                                      cond.periodUseYn === "N",
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        cond,
                                        "periodDiv",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("cp.COMMON.002"))),
                                  ]),
                                  _vm._l(
                                    _vm.eachPeriodGbnList[cond.factorCd],
                                    function (item, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: idx,
                                          domProps: { value: item.detailCd },
                                        },
                                        [_vm._v(_vm._s(item.detailNm))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "input_calendar short" },
                                [
                                  _c("e-date-picker", {
                                    staticStyle: { width: "120px" },
                                    model: {
                                      value: cond.periodFr,
                                      callback: function ($$v) {
                                        _vm.$set(cond, "periodFr", $$v)
                                      },
                                      expression: "cond.periodFr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "input_calendar short" },
                                [
                                  _c("e-date-picker", {
                                    staticStyle: { width: "120px" },
                                    model: {
                                      value: cond.periodTo,
                                      callback: function ($$v) {
                                        _vm.$set(cond, "periodTo", $$v)
                                      },
                                      expression: "cond.periodTo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "input_calendar short" },
                                [
                                  _c("e-date-picker", {
                                    staticStyle: { width: "120px" },
                                    model: {
                                      value: cond.periodFr1,
                                      callback: function ($$v) {
                                        _vm.$set(cond, "periodFr1", $$v)
                                      },
                                      expression: "cond.periodFr1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "input_calendar short" },
                                [
                                  _c("e-date-picker", {
                                    staticStyle: { width: "120px" },
                                    model: {
                                      value: cond.periodTo1,
                                      callback: function ($$v) {
                                        _vm.$set(cond, "periodTo1", $$v)
                                      },
                                      expression: "cond.periodTo1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeCondRows(condIdx)
                                    },
                                  },
                                },
                                [_vm._v("minus")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            _c("div", { staticClass: "flex_box mt8" }, [
              _c(
                "a",
                {
                  staticClass: "button lg blue ml_auto",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.entryValid.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("ENTRY")]
              ),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("cp.CTRL020G070.161"))),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "500px" },
            attrs: { id: "realgrid" },
          }),
          _c("div", { staticClass: "flex_box mt8" }, [
            _c(
              "a",
              {
                staticClass: "button lg blue ml_auto",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exportExcelValidChk.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("cp.CTRL020G070.105")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }